<template>
    <div class="browseRecords">

        <Head :centerInfo="true"></Head> 
        <div class="mauto">
            <h4>我的浏览记录</h4>
            <ul>
                <li class="clearfix" v-for="(item,index) in listData" :key="index"  v-show=" listData.length != 0 && item.userName == $store.state.userName  ">
                    <div v-show="getTimeStatus(item)">
                        <div class="lastplay-time">
                            <i></i>
                            <span>{{timestampToTime(item.seeTime)}}</span>
                        </div>
                        <div class="historyDetail">
                            <p><img @click="jumpPlay(item)" :src="item.coverUrl" alt=""></p>
                            <div>
                                <p>{{item.videoTitle}}</p>
                                <div>
                                    <p class="deleteBtn" @click="removeFn(index)"><span class="iconfont icon-shanchu-01"></span>删除</p>
                                    <p class="historyVideoType">{{item.className}}</p>
                                </div>
                                <span>看到 {{hisToryHandleTime(item.timeSlot/1000)}}</span>
                            </div>
                        </div>
                    </div>
      
                </li>

            </ul>
            <!--无数据-->
            <el-empty v-if="listDataInfo.length == 0" image="http://image.ourgame.com/videoWeb/null.png" description=" ">
            </el-empty>
        </div>
            <!-- 删除评论 -->
    <div class="modal" v-show="deleteInfo">
        <img @click="closeModal" src="~assets/close_icon.png" alt="">
        <p>您确定是否要删除该条历史记录</p>
        <button @click="remove">确定</button>
        <button @click="closeModal">取消</button>
    </div>
    </div>
</template>

<script>
    import Head from '@/components/head.vue';

    export default {
        name: "browseRecords",
        components: {
            Head
        },
        data() {
            return {
                listData: [],
                deleteInfo:false,
                listDataInfo:false,
            }
        },
        mounted() {
            window.scrollTo(0,0)
            if(JSON.parse(localStorage.getItem('historyVideo'))!=null){
                let start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                this.listDataInfo = JSON.parse(localStorage.getItem('historyVideo')).reverse().filter((item,index) => {
                    return item.userName == this.$store.state.userName && item.seeTime>start.getTime()
                })
                this.listData =  JSON.parse(localStorage.getItem('historyVideo'))
                for(let i in this.listData){
                      console.log(this.listData[i].userName)
                      this.$set(this.listData,i,this.listData[i])
                }
              

               
                // localStorage.setItem('historyVideo', JSON.stringify(this.listData))
            }
         
        },
        methods: {
            closeModal() {
        
                this.deleteInfo = false;
            },
            getTimeStatus(item){
                let start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                console.log(item.seeTime>start.getTime())
                if(item.seeTime>start.getTime()){
                    return true
                }else{
                    return false
                }
                
            },
            hisToryHandleTime(s) {
                var t;
                if (s > -1) {
                    var min = Math.floor(s / 60);
                    var sec = s % 60;
                    if (min < 10) {
                        min = "0" + min;
                    }
                    t = min + ":";
                    if (sec < 10) {
                        t += "0";
                    }
                    t += sec;
                }
                return t;
            },
            removeFn(index){
                this.removeIndex = index;
                this.deleteInfo = true
            },
            remove() {
                this.listData.splice(this.removeIndex, 1)
                this.deleteInfo = false;
                localStorage.setItem('historyVideo', JSON.stringify(this.listData))
            },
            jumpPlay(item) {
                this.$router.push({
                    path: '/videoPlay',
                    query: {
                        al: item.aliVideoId,
                        videoId: item.videoID,
                    }
                })
            }
        }
   
    }
</script>
<style lang="less">
    .browseRecords {
        h4 {
            padding: 95px 0px 36px 0px;
            font-size: 14px;
            color: #333333;
        }

        .mauto>ul {
            li {
                height: 218px;
                width: 1146px;
                margin: 0px auto;

                .lastplay-time {
                    height: 218px;
                    border-left: 1px solid #eeeeee;
                    position: relative;
                    float: left;
                    width: 140px;
                    top: 0px;
                    left: 0px;

                    i {
                        width: 8px;
                        height: 8px;
                        background-color: #eeeeee;
                        border-radius: 50%;
                        overflow: hidden;
                        position: absolute;
                        top: 50%;
                        margin-top: -4px;
                        left: -4px;
                    }

                    span {
                        position: absolute;
                        top: 50%;
                        left: 10px;
                        font-size: 12px;
                        margin-top: -7px;
                    }
                }

                .historyDetail {
                    float: left;
                    width: 1000px;

                    >p {
                        float: left;
                        width: 224px;
                        height: 178px;
                        margin-right: 20px;
                        cursor: pointer;
                        overflow: hidden;

                        >img {
                            width: 224px;
                            height: 178px;

                            &:hover {
                                transform: scale(1.2)
                            }
                        }
                    }

                    >div {
                        >p {
                            color: #333333;
                            font-size: 18px;
                            padding-top: 10px;
                        }

                        >span {
                            color: #666666;
                        }

                        >div {
                            font-size: 14px;
                            padding: 55px 0px;

                            p {
                                float: right;
                            }

                            .historyVideoType {
                                color: #999999;
                                padding-right: 180px;
                            }

                            .deleteBtn {
                                color: #00a1d6;
                                cursor: pointer;

                                span {
                                    padding-right: 4px;

                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>